import React from 'react';
import Helmet from 'react-helmet';
import Layout from '../layout/Layout';

import { siteTitle } from '../../data/SiteConfig';
import { NewsletterIframe } from '../components/NewsletterIframe';

const AboutPage = () => (
  <Layout>
    <Helmet title={`Newsletter| ${siteTitle}`} />

    <h1 css={{ marginBottom: 12 }}>Get curated articles</h1>
    <h2 css={{ marginTop: 0 }}>Learn JavaScript like never before</h2>
    <p>
      Hey! I'm Obed and I write <b>visual articles</b> to help you learn{' '}
      <b>JavaScript</b> and <b>React</b> in better ways. I also write about
      productivity and engineering.
    </p>
    <p>I'll only email you for new, great articles, nothing more.</p>

    <NewsletterIframe height={'480px'} />
  </Layout>
);

export default AboutPage;
